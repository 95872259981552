import { BladeProvider } from '@razorpay/blade/components';
import { bankingTheme } from '@razorpay/blade/tokens';
import React from 'react';
import OtpModal, { OtpModalProps } from './OtpModal';

const OtpModalWidget = (
  props: OtpModalProps & {
    data: {
      resendAction: string;
      actionFieldName: string;
    };
  },
) => {
  // function to get the closest form and call the submit of that form
  const onSubmit = (e: React.MouseEvent) => {
    const form = e.currentTarget.closest('form');
    if (form) {
      HTMLFormElement.prototype.submit.call(form);
    }
  };

  const onResend = (e: React.MouseEvent) => {
    e.preventDefault();
    const form = e.currentTarget.closest('form');
    if (form) {
      // Replace the value of hidden input with the action value only if it exists, otherwise create the field and add to the form
      const actionFieldName = props.data.actionFieldName;
      const actionField = form.querySelector(
        `input[name="${actionFieldName}"]`,
      ) as HTMLInputElement;
      if (actionField) {
        actionField.value = props.data.resendAction;
      } else {
        throw new Error('Action field not found');
      }
      HTMLFormElement.prototype.submit.call(form);
    }
  };

  return (
    <BladeProvider themeTokens={bankingTheme} colorScheme="dark">
      <OtpModal onVerify={onSubmit} onResend={onResend} {...props} />
    </BladeProvider>
  );
};

export default OtpModalWidget;
