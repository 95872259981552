import React from 'react';
import styled from 'styled-components';

import { BladeProvider } from '@razorpay/blade/components';
import { Button, Box, Text } from '@razorpay/blade/components';
import { bankingTheme } from '@razorpay/blade/tokens';
import InfoPopup from './InfoPopup';

export const EntryPointWrapper = styled.div(
  ({ theme }) => `
      display: flex;
      border-radius: ${theme.border.radius.medium}px;
      padding: 12.5px  ${theme.spacing[6]}px;
      background: #333A5F;
      flex-wrap: wrap;
      align-items: center;
      gap: ${theme.spacing[4]}px;
    `,
);

const EntryPoint = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <InfoPopup isOpen={isOpen} onClose={handleOnClose} />
      <EntryPointWrapper>
        <Box flexGrow="1">
          <Text size="large" color="surface.text.subdued.lowContrast">
            In order to choose b/w regimes, we’ve displayed your tax liability in both regimes
            below.
          </Text>
          <Text size="large" color="surface.text.subdued.lowContrast">
            For further help, ask Ray to make an informed decision on your regime
            selection and tax saving options!
          </Text>
        </Box>
        <Box flexShrink="0">
          <Button
            variant="secondary"
            size="large"
            margin="0px"
            isFullWidth={false}
            onClick={() => setIsOpen(true)}>
            Optimize tax
          </Button>
        </Box>
      </EntryPointWrapper>
    </>
  );
};

const EntryPointWithProvider = () => {
  return (
    <BladeProvider themeTokens={bankingTheme} colorScheme="dark">
      <EntryPoint />
    </BladeProvider>
  );
};

export default EntryPointWithProvider;
