import { OTPInput } from '@razorpay/blade/components';
import React, { useState } from 'react';

import otpIcon from 'assets/verify-otp.png';
import { LinkButtonV2, StandardButton } from 'components/ui/Button';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from './Login/index.module.scss';
import OtpTimer from './OtpTimer';

export type OtpModalProps = {
  data: {
    is_authenticator_enabled?: boolean;
    email?: string;
    remainingTime: number;
    error?: string;
    errorCode?: string;
  };
  onVerify?: (e: React.MouseEvent) => void;
  onResend?: (e: React.MouseEvent) => void;
  onClose?: () => void;
};

const OtpModal = ({ data, onVerify, onResend }: OtpModalProps) => {
  const [otp, setOtp] = useState<string>('');

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;
    const otpPattern = /^[0-9]{6}$/;

    if (otpPattern.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  return (
    <div className="position-fixed left-0 top-0 flex h-screen w-screen z-50 bg-modal-blur-background">
      <div className="m-auto flex flex-col items-center">
        <div>
          <div className={styles['otp-component']}>
            <img src={getStaticMediaUrl(otpIcon)} alt="" width="120px" />
            <div className={styles['otp-title']}>
              {data?.is_authenticator_enabled
                ? 'Authentication code'
                : 'Enter OTP for verification'}
            </div>
            <div className={styles['otp-sub-title']}>
              {data?.is_authenticator_enabled
                ? 'Open the two-factor authenticator app on your mobile device to view the authentication code'
                : `One Time Password sent successfully to ${data?.email}`}
            </div>
            <OTPInput
              label=""
              errorText={`The ${
                !data?.is_authenticator_enabled ? 'OTP' : 'authentication code'
              } entered is incorrect. Please try again.`}
              value={otp}
              onChange={onOtpChange}
              otpLength={6}
              placeholder=""
              validationState={data.error ? 'error' : 'none'}
            />
            <input type="hidden" name="otp" value={otp} />
            <div className="flex items-center">
              {!data?.is_authenticator_enabled && (
                <div className="mt-[48px] flex items-center">
                  <div className={styles['otp-time-text']}> Did not receive OTP yet?</div>
                  <OtpTimer duration={data.remainingTime} format="mmss" isVisible={true}>
                    {({
                      timerDone,
                      resetTimer,
                    }: {
                      timerDone: boolean;
                      resetTimer: () => void;
                    }) => (
                      <div>
                        {timerDone ? (
                          <LinkButtonV2
                            className="m-0 p-0 normal-case"
                            onClick={(e) => {
                              onResend?.(e);
                            }}>
                            Resend now
                          </LinkButtonV2>
                        ) : (
                          <div style={{ fontSize: 15, color: 'rgba(255, 255, 255, 0.38)' }}>
                            Resend in
                          </div>
                        )}
                      </div>
                    )}
                  </OtpTimer>
                </div>
              )}
            </div>
            <div className="my-[32px] w-full">
              <StandardButton
                className={'w-full m-0 p-0'}
                disabled={!otp}
                onClick={(e) => onVerify?.(e)}>
                Verify
              </StandardButton>
            </div>
            {!data?.is_authenticator_enabled && (
              <>
                <div className={`${styles['otp-help']} mb-4`}>
                  If the OTP isn't in your inbox, check your spam folder.
                </div>
                <div className={styles['otp-help']}>
                  Can't access this email?
                  <a
                    target="_blank"
                    href="https://intercom.help/XPayroll/en/articles/9626549-not-able-to-access-email-id"
                    className="ml-2">
                    <LinkButtonV2 className="normal-case m-0 p-0">Click here.</LinkButtonV2>
                  </a>
                </div>
              </>
            )}
          </div>
          {!data?.is_authenticator_enabled && (
            <div className="text-[14px] mt-10 flex justify-center items-center">
              Need help? Contact
              <a
                target="_blank"
                href="mailto:xpayrollsupport@razorpay.com"
                className="ml-2 font-bold">
                xpayrollsupport@razorpay.com
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
