import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StandardSecondaryButton } from 'components/ui/Button';
import { getStaticMediaUrl } from 'utils/Urls';
import DownloadConfimationModal from './components/DownloadConfimationModal';
import ExportIcon from './../../assets/icon--export-white.svg';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { BulkExportCommonProps } from './types';
import pollForBulkDownload from 'utils/pollForBulkDownload';
import Spinner from 'components/ui/Spinner';

interface ExportOptions {
  confirmHeader?: string;
  buttonText?: string;
  disableOnFreePlan?: boolean;
}

const withBulkExport = <T extends BulkExportCommonProps>(
  Component: React.ComponentType<T>,
  options?: ExportOptions,
) => {
  // This will be used by React Dev Tools
  const displayName = Component.displayName || Component.name || 'Component';

  const ComponentWithBulkExport = (props: Omit<T, keyof BulkExportCommonProps>) => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [showEmailConfirmation, enableConfirmation] = useState<boolean>(false);

    const hasLoggedInUserData = useSelector(loggedInUserSelectors.hasData);
    const isOnFreePlan = useSelector(loggedInUserSelectors.isOnFreePlan);

    const dispatch = useDispatch();
    useEffect(() => {
      if (!hasLoggedInUserData) {
        dispatch(fetchLoggedInUser());
      }
    }, []);

    const [hasOngoingBulkDownload, setOngoingBulkDownload] = useState<boolean>(false);
    useEffect(() => {
      if (isFormOpen) {
        const pollId = sessionStorage.getItem('pollId');
        if (pollId) {
          setOngoingBulkDownload(true);
          const poll = setInterval(function () {
            const pollId = sessionStorage.getItem('pollId');
            if (!pollId) {
              setOngoingBulkDownload(false);
              clearInterval(poll);
            }
          }, 5000);
        } else {
          setOngoingBulkDownload(false);
        }
      }
    }, [isFormOpen]);

    const onCloseModal = () => {
      setIsFormOpen(false);
      enableConfirmation(false);
    };

    const initBulkExport = () => {
      if(hasLoggedInUserData) {
        setIsFormOpen(true);
        enableConfirmation(false);
      }
    };

    const onExportConfirm = (id?: number) => {
      if(!id) {
        return;
      }
      pollForBulkDownload(id);
      setIsFormOpen(false);
      enableConfirmation(true);
    };

    const disableOnPlan = isOnFreePlan && options?.disableOnFreePlan; 

    return (
      <>
        <StandardSecondaryButton
          onClick={initBulkExport}
          disabled={(isFormOpen && !hasLoggedInUserData) || disableOnPlan}
          className="ml-2">
          <div className="flex justify-center align-center">
            <img width="23px" src={getStaticMediaUrl(ExportIcon)} alt="" />
            <span className="pl-4 capitalize font-bold text-white">{options?.buttonText ?? "Export"}</span>
            {isFormOpen && !hasLoggedInUserData && (
              <span className="ml-2 relative top-2">
                <Spinner size={14} />
              </span>
            )}
          </div>
        </StandardSecondaryButton>
        {hasLoggedInUserData && (
          <Component
            {...(props as T)}
            isFormOpen={isFormOpen}
            onClose={onCloseModal}
            onEmailConfirm={onExportConfirm}
            hasOngoingDownload={hasOngoingBulkDownload}
            isOnFreePlan={isOnFreePlan}
          />
        )}
        {showEmailConfirmation && (
          <DownloadConfimationModal
            isOpen={true}
            onConfirm={onCloseModal}
            headerText={
              options?.confirmHeader ? options?.confirmHeader : "We'll email you the documents"
            }
          />
        )}
      </>
    );
  };

  ComponentWithBulkExport.displayName = `withBulkExport(${displayName})`;

  return ComponentWithBulkExport;
};

export default withBulkExport;
